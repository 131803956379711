import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  shouldReload: boolean
}

const initialState: IState = {
    shouldReload: false,
}

const reloadSlice = createSlice({
  name: 'reload',
  initialState,
  reducers: {
    setShouldReload: (state) => {
      state.shouldReload = !state.shouldReload
    },
   
  },
})

export const {
    setShouldReload
} = reloadSlice.actions
export default reloadSlice.reducer
