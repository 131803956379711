import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  models: any[]
  institutions: any[]
  groups: any[]
  devices: any[]
  uploadedDevices: any
}

const initialState: IState = {
  models: [],
  institutions: [],
  groups: [],
  devices: [],
  uploadedDevices: 'notyetuploaded'
}


const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    addModel: (state, action) => {
      state.models.push(action.payload)
    },
    addInstitution: (state, action) => {
      state.institutions.push(action.payload)
    },
    addGroup: (state, action) => {
      state.groups.push(action.payload)
    },
    saveInstitutions: (state, action) => {
      state.institutions = action.payload
    },
    saveModels: (state, action) => {
      state.models = action.payload
    },
    saveGroups: (state, action) => {
      state.groups = action.payload
    },
    saveDevices: (state, action) => {
      state.devices = action.payload
    },
    setUploadedDevices: (state, action) => {
      state.uploadedDevices = action.payload
    },
  },
})

export const {
  addModel,
  saveInstitutions,
  saveModels,
  saveGroups,
  saveDevices,
  setUploadedDevices
} = deviceSlice.actions
export default deviceSlice.reducer
