import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PeopleIcon from '@mui/icons-material/People';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import ShopIcon from '@mui/icons-material/Shop';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useNavigate } from 'react-router-dom';

const actions = [
  { icon: <FormatListNumberedIcon />, name: 'Tasks' },
  { icon: <ShopIcon />, name: 'Apps' },
  { icon: <AdUnitsIcon />, name: 'Devices' },
  { icon: <PeopleIcon />, name: 'Users' },
];

export default function ControlledOpenSpeedDial() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate()

  const handleClick = (action: any) => {
    if(action === "Tasks") {
      navigate("/dashboard/app-store/app-distribution")
    } else if(action === "Apps") {
      navigate("/dashboard/app-store/app-catalogue")
    }else if(action === "Devices") {
      navigate("/dashboard/devices")
    }else if(action === "Users") {
      navigate("/dashboard/users")
    }
  }

  return (
    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
