import { createSlice } from '@reduxjs/toolkit'

interface UserTypeState {
  name: string
  description: string
  id: string
 
}

const initialState: UserTypeState = {
  name: "",
  id: "",
  description: ""
}

const userTypeSlice = createSlice({
  name: 'userType',
  initialState,
  reducers: {
    setUserTypeDetails: (state, action) => {
      state.name = action.payload.name
      state.description = action.payload.description
      state.id = action.payload.id
    }, 
   
    
  },
})

export const {
  setUserTypeDetails
  
} = userTypeSlice.actions
export default userTypeSlice.reducer
