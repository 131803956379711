import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  id: String
  name: String
  description: String
}

const initialState: IState = {
    id: "",
    name: "",
    description: "",
}

const groupSlice = createSlice({
  name: 'groupDetails',
  initialState,
  reducers: {
    setGroupDetails: (state, action) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.description = action.payload.description
    },
   
  },
})

export const {
    setGroupDetails
} = groupSlice.actions
export default groupSlice.reducer
