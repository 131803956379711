import 'leaflet/dist/leaflet.css'
import { useState, useEffect } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

interface PropertiesMapProps {
  lan: number
  lon: number
}

const DeviceMap = (props: PropertiesMapProps) => {
  let DefaultIcon = L.icon({ iconUrl: icon, shadowUrl: iconShadow })

  const [latitude, setLatitude] = useState<number | null>(null)
  const [longitude, setLongitude] = useState<number | null>(null)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (p) {
        setLatitude(p.coords.latitude)
        setLongitude(p.coords.longitude)
      },
      function (err) {
        console.warn('Error Code' + err.code + ': ' + err.message)
        alert('Check the console')
      },
    )

    var container = L.DomUtil.get('myMap') as HTMLElement
    if (container !== null) {
      ;(container as any)._leaflet_id = null
    }

    var map = L.map('myMap').setView([latitude || 0, longitude || 0], 12)

    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoidGFyLWhlbCIsImEiOiJjbDJnYWRieGMwMTlrM2luenIzMzZwbGJ2In0.RQRMAJqClc4qoNwROT8Umg',
      },
    ).addTo(map)

    L.Marker.prototype.options.icon = DefaultIcon
    var destinationMarker = L.marker([props.lan, props.lon]).addTo(map)
    destinationMarker.bindPopup('<b>destination</b>').openPopup()

    var userLocationMarker = L.marker([latitude || 0, longitude || 0]).addTo(
      map,
    )
    userLocationMarker.bindPopup('<b>Your location</b>').openPopup()
  }, [latitude, longitude, props.lan, props.lon])
  return (
    <div className='flex mt-10'>
      <div id='myMap' style={{ height: '40vh' }}></div>
    </div>
  )
}

export default DeviceMap
