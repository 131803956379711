import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  start: string
  end: string
  minDate: object

}

const initialState: IState = {
    start: "",
    end: "",
    minDate: {}
}

const dateSlice = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.start = action.payload
    },
    setEndDate: (state, action) => {
      state.end = action.payload
    },
    setMinDate: (state, action) => {
      state.minDate = action.payload
    },
  },
})

export const {
    setStartDate, setEndDate, setMinDate
} = dateSlice.actions
export default dateSlice.reducer
