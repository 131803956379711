import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  focusedTextField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3498db', // Default state border color
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#032541', // Focused state border color
      },
    },
    '& .MuiInputLabel-outlined': {
      '&.Mui-focused': {
        color: '#032541', // Focused state label color
      },
    },
  },
}))

function SearchableSelect6({ apps, handleChange }) {
  const classes = useStyles()
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    if (apps) {
      const uniqueOptions = apps.map((obj) => ({
        label: obj.appname,
        id: obj.id,
      }))
      setOptions(uniqueOptions)
    }
  }, [apps])

  useEffect(() => {
    if (selectedOption) {
      handleChange({
        target: {
          name: 'appId',
          value: selectedOption.id,
        },
      })
    }
  }, [selectedOption])

  return (
    <Autocomplete
      variant='outlined'
      fullWidth
      size='small'
      value={selectedOption}
      onChange={(event, newValue) => {
        setSelectedOption(newValue)
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Select App'
          variant='outlined'
          className={classes.focusedTextField}
        />
      )}
    />
  )
}

export default SearchableSelect6
