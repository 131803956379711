import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  id: String
  name: String
  description: String
  androidVersion: String
  supportedDevices: String
  creator: String
  iconSupport: boolean
}

const initialState: IState = {
    id: "",
    name: "",
    description: "",
    androidVersion: "",
    creator: "",
    supportedDevices: "",
    iconSupport: false
}

const modelSlice = createSlice({
  name: 'modelDetails',
  initialState,
  reducers: {
    setModelDetails: (state, action) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.description = action.payload.description
      state.androidVersion = action.payload.androidVersion
      state.supportedDevices = action.payload.supportedDevices
      state.creator = action.payload.creator
      state.iconSupport = action.payload.iconSupport
    },
   
  },
})

export const {
    setModelDetails
} = modelSlice.actions
export default modelSlice.reducer
