import { createSlice } from '@reduxjs/toolkit'

interface User {
  id: number
  name: string
  username: string
  email: string
  status: string
}
interface UserState {
  users: User[]
  userTypes: any[]
  roles: any[]
  X_Authorization: string
  isLoggedIn: boolean
  role: string
  action: string
}

const initialState: UserState = {
  users: [],
  roles: [],
  userTypes: [],
  X_Authorization: '',
  isLoggedIn: false,
  role: 'user',
  action: 'activate',
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.X_Authorization = action.payload
    },
    saveUser: (state, action) => {
      state.users.push(action.payload)
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setUserRole: (state, action) => {
      state.role = action.payload
    },
    saveUsers: (state, action) => {
      state.users = action.payload
    },
    saveRoles: (state, action) => {
      state.roles = action.payload
    },
    saveUserTypes: (state, action) => {
      state.userTypes = action.payload
    },
    setAction: (state, action) => {
      state.action = action.payload
    },
  },
})

export const {
  saveUser,
  saveUsers,
  saveRoles,
  setToken,
  setIsLoggedIn,
  setUserRole,
  setAction,
  saveUserTypes,
} = userSlice.actions
export default userSlice.reducer
