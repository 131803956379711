import { CiLock } from "react-icons/ci";
import {ChangeEvent, FormEvent, useState} from "react";
const mdmUrl = process.env.REACT_APP_BASE_URL;

interface ResetPasswordProps{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}
const ResetPassword = () =>{

    const[formData, setFormData] = useState<ResetPasswordProps>({
        currentPassword:'',
        newPassword:'',
        confirmPassword:''
    })

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    };

    // handling reset password
    const handleResetPassword = async (e:FormEvent) =>{
        e.preventDefault();
        try {
            const res = await fetch(`${mdmUrl}`)
            const data = await res.json();
            console.log(data);
        }catch(err){
            console.error(err);
        }
    }

    // destructure state from formData
    const{ currentPassword, newPassword, confirmPassword} = formData;
    return(
        <div className='flex flex-col w-full px-40 py-50 m-auto justify-center items-center shadow-2xl'>
            <i><CiLock size={30} fill='#346beb'/></i>
            <h3 className='flex text-xl font-bold py-3'>Change Password</h3>
            <p className='flex text-lg opacity-40'>To change your password, fill in the fields below!</p>
            <form className='flex flex-col mt-7 w-1/4' onSubmit={handleResetPassword}>
                <div className="flex flex-col mt-5">
                    <label htmlFor="currentPassword">Current Password</label>
                    <input type="text" name='currentPassword' onChange={handleChange} value={currentPassword} placeholder='Current Password' className='flex px-3 py-3 mt-3 w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'/>
                </div>
                <div className="flex flex-col mt-5">
                    <label htmlFor="newPassword">New Password</label>
                    <input type="text" name='newPassword' value={newPassword} onChange={handleChange} placeholder='New Password' className='flex px-3 py-3 mt-3 w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'/>
                </div>
                <div className="flex flex-col mt-5">
                <label htmlFor="confirmPassword">Confirm Password</label>
                    <input type="text" name='confirmPassword' value={confirmPassword} onChange={handleChange} placeholder='Confirm Password' className='flex px-3 py-3 mt-3  w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'/>
                </div>
                <button className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-3 mt-10 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>Change Password</button>
            </form>
        </div>
    )
}

export default ResetPassword;