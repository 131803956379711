import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, redirect } from 'react-router-dom'
import Login from './pages/authentication/Login'
import Loader from './common/Loader'
import { Toaster } from 'react-hot-toast'
import './App.css'
import router from './routes/routes'
import ResentVisits from './pages/dashboard/ResentVisits'
import DefaultLayout from './layout/DefaultLayout'
import ResetPassword from './pages/authentication/ResetPassword'
import { useDispatch, useSelector } from 'react-redux'
// import {
//   saveDevices,
//   saveGroups,
//   saveInstitutions,
//   saveModels,
// } from './features/slices/deviceSlice'

const mdmUrl = process.env.REACT_APP_BASE_URL

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  const { X_Authorization, isLoggedIn } = useSelector(
    (state: any) => state.users,
  )
  return loading ? (
    <Loader />
  ) : (
    <>
      <Toaster
        position='top-right'
        reverseOrder={false}
        containerClassName='overflow-auto'
      />
      <Routes>
        <Route
          path='/'
          element={!isLoggedIn ? <Login /> : <Navigate to={'/dashboard'} />}
        />
        <Route path='/reset-password' element={<ResetPassword />} />
        {isLoggedIn && (
          <Route path='/dashboard' element={<DefaultLayout />}>
            <Route index element={<ResentVisits />} />
            {router.map((routes, index) => {
              const { path, component: Component } = routes
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <Component />
                    </Suspense>
                  }
                />
              )
            })}
          </Route>
        )}
      </Routes>
    </>
  )
}

export default App
