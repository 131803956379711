import React, { useEffect, useState } from 'react'
import Logout from '../pages/authentication/Logout'
import { useDispatch, useSelector } from 'react-redux'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { setIsOpen, setWidth } from '../features/slices/sidebarSlice';
import MenuIcon from '@mui/icons-material/Menu';
interface NavBarProps {
  sidebarOpen: string | boolean | undefined
  setSidebarOpen: (arg0: boolean) => void
}

const Navbar: React.FC<NavBarProps> = ({setSidebarOpen, sidebarOpen }: NavBarProps) => {
  const { isLoggedIn } = useSelector((store: any) => store.users)

  const isSidebarOpen = useSelector((state: any) => state.sidebar.isOpen)
  
  const dispatch = useDispatch()

  const handleSidebarButton = () => {
    dispatch(setWidth('w-72.5'))
    dispatch(setIsOpen(true))
    setSidebarOpen(true)
  }

  return (
    <header className='sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none'>
      <div className='flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11'>
        {/* <div className='flex items-center gap-2 sm:gap-4 lg:hidden'>
          <button
            aria-controls='sidebar'
            onClick={(e) => {
              e.stopPropagation()
              props.setSidebarOpen(!props.sidebarOpen)
            }}
            className='z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden'
          >
            <span className='relative block h-5.5 w-5.5 cursor-pointer'>
              <span className='du-block absolute right-0 h-full w-full'>
                <span
                  className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!w-full delay-300'
                  }`}
                ></span>
                <span
                  className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && 'delay-400 !w-full'
                  }`}
                ></span>
                <span
                  className={`relative top-0 left-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!w-full delay-500'
                  }`}
                ></span>
              </span>
              <span className='absolute right-0 h-full w-full rotate-45'>
                <span
                  className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!h-0 !delay-[0]'
                  }`}
                ></span>
                <span
                  className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
                    !props.sidebarOpen && '!h-0 !delay-200'
                  }`}
                ></span>
              </span>
            </span>
          </button>
          </div> */}
          {!isSidebarOpen ? <MenuIcon onClick={handleSidebarButton}/> : null}
        {isLoggedIn && <Logout />}
      </div>
    </header>
  )
}

export default Navbar
