import AppCatalogueChart from '../../components/AppCatalogueChart'
import DevicesBarChart from '../../components/DevicesBarChart'
import DeviceMap from '../../components/DeviceMap'
import RecentUsers from '../../components/RecentUsers'

const ResentVisits: React.FC = () => {
  return (
    <div className='flex w-full flex-col'>
      <h1 className='text-xl text-body'>Recent Visits</h1>
      <div className='grid grid-cols-3 gap-10 mt-10 border-2 border-gray rounded p-5'>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-medium'>App Catalogue</h3>
          <AppCatalogueChart />
        </div>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-medium'>Devices</h3>
          <DevicesBarChart />
        </div>
        <div className='flex flex-col w-full'>
          <h3 className='text-normal font-medium'>Users</h3>
          <RecentUsers />
        </div>
      </div>
      <DeviceMap lan={0} lon={0} />
    </div>
  )
}

export default ResentVisits
