import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { setEndDate,  } from '../features/slices/dateSlice';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess() 


export default function DateTimePickerValue() {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());

  const dispatch = useDispatch();

  const minDate = useSelector((state: any) => state.dates.minDate)

  // const [newMinDate, setNewMinDate] = useState(new Date())
  const [newMinDate, setNewMinDate] = useState(dayjs(new Date()));

  useEffect(() => {
    if(minDate) {
      // Ensure minDate is a Dayjs object before performing operations on it
      const minDateObj = dayjs(minDate);
      // Check if minDateObj is valid
      if (minDateObj.isValid()) {
        const x = minDateObj.add(3, 'hour');
        setNewMinDate(x);
      } else {
        console.error('Invalid minDate:', minDate);
      }
    }
  }, [minDate]);
  

  useEffect(() => {
    if (value) {
        console.log('before format', value)
        const stringValue = value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        console.log('value:', stringValue);
        dispatch(setEndDate(stringValue))
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label="Select end date"
          value={value}
          ampm={false}
          disableIgnoringDatePartForTimeValidation={true}
          minDateTime={newMinDate}
          onChange={(newValue) => setValue(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
