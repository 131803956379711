import { combineReducers } from "@reduxjs/toolkit";
import deviceSlice from "./slices/deviceSlice";
import userSlice from "./slices/userSlice";
import detailsSlice from "./slices/detailsSlice";
import dateSlice from "./slices/dateSlice";
import reloadSlice from "./slices/reloadSlice";
import sidebarSlice from "./slices/sidebarSlice";
import userTypeSlice from "./slices/userTypeSlice";
import modelSlice from "./slices/modelSlice";
import InstitutionSlice from "./slices/InstitutionSlice";
import groupSlice from "./slices/groupSlice";

const rootSlice = combineReducers({
  device: deviceSlice,
  users: userSlice,
  details: detailsSlice,
  dates: dateSlice,
  reload: reloadSlice,
  sidebar: sidebarSlice,
  userType: userTypeSlice,
  modelDetails: modelSlice,
  institutionDetails: InstitutionSlice,
  groupDetails: groupSlice
})

export default rootSlice;