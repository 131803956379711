import {useSelector} from "react-redux";
import {RootState} from "../features/store";
import { Paper, Stack, Typography } from "@mui/material";
const token: any = localStorage.getItem('X_Authorization');

const RecentUsers = () =>{
    const {users} = useSelector((store: RootState) => store.users)
    console.log(users);
    return(
        <Stack mt={2}>
        <Paper elevation={2}>
          <Stack p={2} height={'350px'}>
            
          </Stack>
        </Paper>
      </Stack>
    )
}

export default RecentUsers;