import { IoMdLogOut } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HashLoader } from 'react-spinners'
import {
  setIsLoggedIn,
  setToken,
  setUserRole,
} from '../../features/slices/userSlice'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const Logout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handleLogout = async () => {
    try {
      setLoading(true)
      dispatch(setIsLoggedIn(false))
      dispatch(setUserRole('user'))
      dispatch(setToken(''))
      navigate('/')
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
    }
  }
  return (
    <>
      <ToastContainer />
      <button
        className='flex items-center p-3 rounded-br-lg bg-black w-32 text-white'
        onClick={handleLogout}
      >
        <i className='flex mr-2'>
          <IoMdLogOut size={20} />
        </i>
        Logout
      </button>
      {loading && <HashLoader color='#000' />}
    </>
  )
}

export default Logout
