import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  PacmanLoader,
  SyncLoader,
} from 'react-spinners'
import {
  setToken,
  setIsLoggedIn,
  setUserRole,
} from '../../features/slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'

const mdmUrl = process.env.REACT_APP_BASE_URL
const Login = (): JSX.Element => {
  const navigate = useNavigate()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [captcha, setCapcha] = useState<string>('')
  const [captchaId, setCaptchaId] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector((store: any) => store.users)

  const handleLogin = async (e: { preventDefault: () => void }) => {
    // prevent default behavior
    e.preventDefault()
    setLoading(true)
    setSubmitted(true)
    const user = { username, password, captchaId, captchaValue: captcha }
    try {
      if (username.trim() !== '' && password.trim() !== '' && captcha.trim() !== '') {
        const response = await fetch(mdmUrl + '/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(user),
        })
        const data = await response.json()
        console.log(data.token)
        if (response.status === 200) {
          dispatch(setIsLoggedIn(true))
          dispatch(setUserRole('admin'))
          dispatch(setToken(data.token))
          console.log(data)
          navigate('/dashboard')
        } else {
          dispatch(setIsLoggedIn(false))
          setLoading(false)
          toast.error(`${data.error}`, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'login-toast',
            theme: 'colored',
          })
        }
      } else {
        toast.error('Please fill in the fields', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'login-toast',
          theme: 'colored',
        })
        setLoading(false)
      }
    } catch (err) {
      setIsLoggedIn(false)
      setLoading(false)
      toast.error(`${err}`, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'login-toast',
        theme: 'colored',
      })
    }
  }

  useEffect(() => {
    // Check if the user is already logged in
    if (isLoggedIn) {
      // Redirect to the homepage
      navigate('/homepage')
    }
  }, [isLoggedIn])

  const [captchaSource, setCapchaSource] = useState('')
  const [loading2, setLoading2] = useState(false)

  const { X_Authorization } = useSelector((state: any) => state.users)

  const getCaptcha = async () => {
    setLoading2(true)
    try {
      const res = await fetch(mdmUrl + '/api/captcha/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${X_Authorization}`,
        },
        // body: JSON.stringify(formData),
      })
      const data = await res.json()
      console.log(data)
      if (data.code === 1) {
        setCapchaSource(data.data)
        setCaptchaId(data.captchaId)
        setLoading2(false)
        console.log(data)
      } else {
        setLoading2(false)
      }
    } catch (err) {
      setLoading2(false)
      console.error(err)
    }
  }

  const [reload, setReload] = useState(false)

  const handleReload = () => {
    setReload(!reload)
    setCapcha("")
  }

  // const shouldReload = useSelector((state: any) => state.reload.shouldReload)

  useEffect(() => {
    getCaptcha()
  }, [reload])

  return (
    <>
      {!isLoggedIn && (
        <div className='flex min-h-full flex-col justify-center px-6 py-12 lg:px-8'>
          <ToastContainer />
          <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
            <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
              Sign in to your account
            </h2>
          </div>
          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm shadow-2xl p-10'>
            <form className='space-y-6' onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor='username'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Username
                </label>
                <div className='mt-2'>
                  <input
                    id='username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    type='text'
                    autoComplete='username'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm placeholder:text-gray-400 focus:ring-red-500 focus:ring-2 sm:text-sm sm:leading-6
                        ${submitted ? (password.trim() === '' || username.trim() === '' ? 'border-red-500' : 'border-0') : 'border-0'}`}
                  />
                </div>
              </div>
              <div>
                <div className='flex items-center justify-between'>
                  <label
                    htmlFor='password'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Password
                  </label>
                  <div className='text-sm'>
                    <NavLink
                      // to={'/forgot-password'}
                      to={'/'}
                      className='font-semibold text-indigo-600 hover:text-indigo-500'
                    >
                      Forgot password?
                    </NavLink>
                  </div>
                </div>
                <div className='mt-2'>
                  <input
                    id='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                    autoComplete='current-password'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm  placeholder:text-gray-400 focus:ring-red-500 focus:ring-2  sm:text-sm sm:leading-6
                        ${submitted ? (password.trim() === '' || username.trim() === '' ? 'border-red-500' : 'border-0') : 'border-0'}`}
                  />
                </div>
                <Stack
                  mt={2}
                  width={'100%'}
                  height={'100px'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  p={2}
                >
                  {loading2 ? (
                    <i className='flex justify-center items-center w-full '>
                      <SyncLoader color='#121212' margin={4} />
                    </i>
                  ) : (
                    <img alt='captcha-image' src={captchaSource} />
                  )}
                </Stack>

                {/* <img alt='captcha-image' src={captchaSource} /> */}
                <Stack direction={'row'} spacing={3}>
                  <label
                    htmlFor='Captcha'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Captcha
                  </label>
                  <CachedIcon onClick={handleReload}/>
                </Stack>
                <div className='mt-2'>
                  <input
                    id='captcha'
                    value={captcha}
                    onChange={(e) => setCapcha(e.target.value)}
                    type='text'
                    // autoComplete='current-password'
                    className={`block w-full rounded-md py-3 px-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none shadow-sm  placeholder:text-gray-400 focus:ring-red-500 focus:ring-2  sm:text-sm sm:leading-6
                        ${submitted ? (captcha.trim() === '' || password.trim() === '' || username.trim() === '' ? 'border-red-500' : 'border-0') : 'border-0'}`}
                  />
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-black px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  {loading ? <PacmanLoader color='#fff' /> : 'Sign in'}
                </button>
              </div>
            </form>
            <p className='mt-10 text-center text-sm font-medium text-gray-500'>
              Not Registered?
              <NavLink
                // to={'/register'}
                to={'/'}
                className='ml-2 font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
              >
                Create Account
              </NavLink>
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default Login
