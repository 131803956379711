import React, { useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch } from 'react-redux';
import { setMinDate, setStartDate } from '../features/slices/dateSlice';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess() 

export default function DateTimePickerValue() {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs());

  const dispatch = useDispatch();

  useEffect(() => {
    if (value) {
        console.log('before format', value)
        const stringValue = value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        console.log('value:', stringValue);
        dispatch(setStartDate(stringValue))
        dispatch(setMinDate(value))
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label="Select start date"
          value={value}
          ampm={false}
          onChange={(newValue) => setValue(newValue)}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
