import { Paper, Stack, Typography } from "@mui/material";
import Chart from "react-apexcharts";

const chartConfig: any = {
    type: "bar",
    height: 265,
    width: 370,
    series: [
        {
            name: "Devices",
            data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
        },
    ],
    options: {
        chart: {
            toolbar: {
                show: false,
            },
        },
        title: {
            show: "",
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#9575cd"],
        plotOptions: {
            bar: {
                columnWidth: "40%",
                borderRadius: 2,
            },
        },
        xaxis: {
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
            categories: [
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#616161",
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 400,
                },
            },
        },
        grid: {
            show: true,
            borderColor: "#dddddd",
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 5,
                right: 20,
            },
        },
        fill: {
            opacity: 0.8,
        },
        tooltip: {
            theme: "blue",
        },
    },
};
const AppCatalogueChart = () =>{
    return(
        <Stack mt={2}>
        <Paper elevation={2}>
          <Stack p={2}>
            <Typography fontWeight={'bold'}>Device Status</Typography>
            <Typography>Visualize your recent devices data.</Typography>
            <Stack className='mt-4 grid place-items-center px-2'>
              <Chart {...chartConfig} />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    )
}

export default AppCatalogueChart;
