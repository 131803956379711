import { Paper, Stack, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
interface ChartConfig {
  type: string
  width: number
  height: number
  series: number[]
  options: {
    chart: {
      toolbar: {
        show: boolean
      }
    }
    title: {
      show: string | boolean
    }
    dataLabels: {
      enabled: boolean
    }
    colors: string[]
    legend: {
      show: boolean
    }
  }
}

const chartConfig: ChartConfig | any = {
  type: 'pie',
  width: 380,
  height: 380,
  series: [90, 10],
  options: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      show: 'Total Devices',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#8bc34a', '#9575cd'],
    legend: {
      show: true,
    },
  },
}
const AppCatalogueChart: React.FC = () => {
  return (
    <Stack mt={2}>
      <Paper elevation={2}>
        <Stack p={2}>
          <Typography fontWeight={'bold'}>Total Devices</Typography>
          <Stack direction='row' mt={2}>
            <div className='flex flex-col w-48 justify-center items-center'>
              <p className='text-sm font-medium'>
                <strong className='text-green-400'>8</strong> /117
              </p>
              <p className='text-sm text-blue-gray-400'>Online Devices</p>
            </div>
            <div className='flex flex-col w-48 justify-center items-center'>
              <p className='text-sm font-medium'>0</p>
              <p className='text-sm text-blue-gray-400'>Added within 7 days</p>
            </div>
          </Stack>
          <Stack className='mt-4 grid place-items-center px-2'>
            <Chart {...chartConfig} />
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  )
}

export default AppCatalogueChart
