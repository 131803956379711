import { createSlice } from '@reduxjs/toolkit'

export interface IState {
  groupId: String
  appId: String
  groupName: String
  deviceId: String
  deviceSerial: String
  taskId: String
  taskName: String
  taskDescription: String
  deviceGroupId: String
  userId: String
  clicked_users_name: String
  username: String
  email: String

}

const initialState: IState = {
    groupId: "",
    appId: "",
    groupName: "",
    deviceId: "",
    deviceSerial: "",
    taskId: "",
    taskName: "",
    taskDescription: "",
    deviceGroupId: "",
    userId: "",
    clicked_users_name: "",
    username: "",
    email: ""
}

const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setGroupId: (state, action) => {
      state.groupId = action.payload
    },
     setGroupName: (state, action) => {
      state.groupName = action.payload
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload
    },
    setDeviceSerial: (state, action) => {
      state.deviceSerial = action.payload
    },
    setTaskDetails: (state, action) => {
      state.taskId = action.payload.id
      state.taskName = action.payload.name
      state.taskDescription = action.payload.description
    },
    setDeviceGroupId: (state, action) => {
      state.deviceGroupId = action.payload
    },
    setAppId: (state, action) => {
      state.appId = action.payload
    },
    setUserDetails: (state, action) => {
      state.userId = action.payload.id
      state.clicked_users_name = action.payload.name
      state.username = action.payload.username
      state.email = action.payload.email
    },
  },
})

export const {
  setUserDetails, setGroupId, setDeviceId, setGroupName, setDeviceSerial, setTaskDetails, setDeviceGroupId, setAppId
} = detailsSlice.actions
export default detailsSlice.reducer
